
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Bus from "@/common/ts/Bus";
import Toast from "@/common/ts/Toast";
import { AxiosRequestConfig } from "node_modules/axios";
import store from "@/store";

export default defineComponent({
  name: "validation",
  data() {
    return {
      user: {},
      loading: false,
      ruleForm: {
        action_type: "",
        id: ""
      },
      rules: {
        ask_content: [
          {
            required: true,
            message: "请输入提问内容",
            trigger: "change"
          }
        ]
      }
    };
  },
  methods: {
    initData(action_type, class_id, parent_id) {
      this.resetForm();
      if (action_type == "add") {
        this.ruleForm.action_type = action_type;
        (this.ruleForm as any).class_id = class_id;
        (this.ruleForm as any).parent_id = parent_id;
      }
      this.user = store.getters.currentUser;
    },
    loadData() {
      Bus.$emit("loadDataAsk");
    },
    submitForm() {
      (this.$refs["ruleForm"] as any).validate((valid) => {
        if (valid) {
          this.loading = true;
          ApiService.post(
            "MemberAsk/AskAdd",
            this.ruleForm as AxiosRequestConfig
          )
            .then(({ data }) => {
              if (data.success == true) {
                this.resetForm();
                (this.$refs["btnClose"] as any).click();
                this.loadData();
                Toast.success(data.message);
              } else {
                Toast.warning(data.message);
              }
            })
            .catch((error) => {
              Toast.error(error.message);
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    resetForm() {
      (this.$refs["ruleForm"] as any).resetFields();
    }
  }
});
