
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  data() {
    return {
      path: "",
      row: {},
      //加载
      loading: false,
      //分页
      pagination: {
        totalRows: -1
      },
      //数据
      tableData: []
    };
  },
  methods: {
    initData(row, path) {
      this.path = path;
      this.row = row;
      this.loadData();
    },
    handleExamReady(row) {
      const params = {
        row: JSON.stringify(row), //tb_class_exam_view
        path: this.$route.path
      };
      store.dispatch(Actions.SET_SECONDARY_PARAMS_ACTION, params);
      this.$router.push({
        path: "/exam/ready"
      });
    },
    loadData() {
      const data = {
        class_id: (this.row as any).class_id
      };
      this.loading = true;
      ApiService.post("MemberExam/Exam", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data.items;
            this.pagination.totalRows = data.data.meta.totalRows;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
});
