
import { defineComponent } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import { setCurrentPageButton } from "@/core/helpers/toolbar";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import KTCourseContent from "@/views/class/course/CourseContent.vue";
import KTCourse from "@/views/class/course/Course.vue";
import KTExam from "@/views/class/exam/Exam.vue";
import KTMember from "@/views/class/member/Member.vue";
import KTAsk from "@/views/class/ask/Ask.vue";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "validation",
  components: {
    KTCourseContent,
    KTCourse,
    KTExam,
    KTAsk,
    KTMember
  },
  data() {
    return {
      path: "",
      row: {} as any,
      learn_progress: {} as any,
      nav_index: null
    };
  },
  methods: {
    handleWatchVideo() {
      const params = {
        row: JSON.stringify(this.row),
        path: this.$route.path
      };
      store.dispatch(Actions.SET_SECONDARY_PARAMS_ACTION, params);
      this.$router.push({
        path: "/course/detail"
      });
    },
    loadData() {
      const class_id = (this.row as any).class_id;
      ApiService.post("Class/ClassDetail", {
        class_id
      } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.row = data.data;
            this.handleCourseLink(); //改用promise模式
            this.getLearnProgress(); //获取学习进度
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    /* 学习进度百分比 */
    getLearnProgress() {
      const class_id = (this.row as any).class_id;
      ApiService.post("MemberClass/LearnProgress", {
        class_id
      } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.learn_progress = data.data;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleCoursePause() {
      Bus.$emit("handleCoursePause");
    },
    handleCourseContentLink() {
      this.handleCoursePause();
    },
    handleCourseLink() {
      this.setNavIndex(1);
      (this.$refs["KTCourse"] as any).initData(this.row, this.path);
    },
    handleExamLink() {
      this.handleCoursePause();
      this.setNavIndex(2);
      (this.$refs["KTExam"] as any).initData(this.row, this.path);
    },
    handleMemberLink() {
      this.handleCoursePause();
      (this.$refs["KTMember"] as any).initData(this.row);
    },
    handleAskLink() {
      this.handleCoursePause();
      (this.$refs["KTAsk"] as any).initData(this.row);
    },
    setNavIndex(index) {
      const params = store.getters.currentParams;
      params.nav_index = index;
      store.dispatch(Actions.SET_SECONDARY_PARAMS_ACTION, params);
    },
    initBus() {
      //用于detail调用
      Bus.$on("loadData", () => {
        this.loadData();
      });
      Bus.$on("handleBack", () => {
        this.$router.push({ path: this.path });
      });
    }
  },
  mounted() {
    setCurrentPageBreadcrumbs("管理", ["班级"]);
    setCurrentPageButton("back", true);
    const params = store.getters.currentParams;
    this.row = JSON.parse(params.row); //tb_class_view
    this.path = params.path;
    this.nav_index = params.nav_index; //选项卡
    if (this.nav_index == 2) {
      (this.$refs.btnExamLink as any).click();
    } else {
      (this.$refs.btnCourseLink as any).click();
    }
    this.initBus();
    this.getLearnProgress();
  }
});
