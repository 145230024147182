
import { defineComponent } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "kt-widget-9",
  components: {},
  data() {
    return {
      row: {},
      //加载
      loading: false,
      //分页
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: -1
      },
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //过滤
      owner: "mine",
      //数据
      tableData: []
    };
  },
  methods: {
    initData(row) {
      this.row = row;
      this.loadData();
    },
    loadData() {
      const data = {
        prefix: {
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          searchKey: this.searchKey,
          sortField: this.sortField,
          sortOrder: this.sortOrder
        },
        class_id: (this.row as any).class_id
      };
      this.loading = true;
      ApiService.post("MemberClass/Member", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data.items;
            this.pagination.totalRows = data.data.meta.totalRows;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSizeChange(event) {
      this.pagination.perPage = parseInt(event.target.value);
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.loadData();
    },
    handleExamList() {
      this.loadData();
    }
  },
  mounted() {
    setCurrentPageTitle("考试");
    this.loadData();
  }
});
