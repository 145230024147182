
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast.js";
import { AxiosRequestConfig } from "node_modules/axios";
import store from "@/store";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "kt-widget-9",
  props: {
    widgetClasses: String
  },
  data() {
    return {
      path: "",
      class_id: "",
      course_id: "",
      //课程
      row: {},
      //章节列表
      list_chapter: [],
      //分页
      currentPage: 1,
      perPage: 10,
      //搜索
      searchKey: "",
      //排序
      sortField: "id",
      sortOrder: "descending",
      //数据
      totalRows: 0,
      tableData: []
    };
  },
  methods: {
    initData(row, path) {
      this.path = path;
      this.row = row;
      this.loadData();
    },
    handleDetail() {
      const params = {
        row: JSON.stringify(this.row),
        path: this.$route.path
      };
      store.dispatch(Actions.SET_SECONDARY_PARAMS_ACTION, params);
      this.$router.push({
        path: "/course/detail"
      });
    },
    loadData() {
      const data = {
        class_id: (this.row as any).class_id,
        course_id: (this.row as any).course_id
      };
      ApiService.post("MemberCourse/ChapterList", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data;
            this.totalRows = this.tableData ? this.tableData.length : 0;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    }
  }
});
