
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast";
import { AxiosRequestConfig } from "node_modules/axios";

export default defineComponent({
  name: "modal-card",
  props: {
    course_id: String
  },
  data() {
    return {
      row: {}
    };
  },
  methods: {
    getCourseDetail() {
      const data = {
        course_id: this.course_id
      };
      ApiService.post("MemberCourse/CourseDetail", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.row = data.data;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    }
  },
  mounted() {
    this.getCourseDetail();
  }
});
