
import { defineComponent } from "vue";
import ApiService from "@/core/services/ApiService";
import Toast from "@/common/ts/Toast";
import { AxiosRequestConfig } from "node_modules/axios";
import Bus from "@/common/ts/Bus";
import KTDetail from "@/views/class/ask/AskDetail.vue";
import store from "@/store";

export default defineComponent({
  name: "modal-card",
  components: {
    KTDetail
  },
  data() {
    return {
      user: {},
      row: {},
      //加载
      loading: false,
      //分页
      pagination: {
        currentPage: 1,
        perPage: 10,
        totalRows: -1
      },
      //搜索
      searchKey: "",
      //排序
      sortField: "create_time",
      sortOrder: "descending",
      //数据
      tableData: []
    };
  },
  methods: {
    initData(row) {
      this.row = row;
      this.loadData();
      this.initBus();
    },
    loadData() {
      const data = {
        prefix: {
          currentPage: this.pagination.currentPage,
          perPage: this.pagination.perPage,
          searchKey: this.searchKey,
          sortField: this.sortField,
          sortOrder: this.sortOrder
        },
        class_id: (this.row as any).class_id
      };
      this.loading = true;
      ApiService.post("MemberAsk/Ask", data as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            this.tableData = data.data.items;
            this.pagination.totalRows = data.data.meta.totalRows;
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSizeChange(event) {
      this.pagination.perPage = parseInt(event.target.value);
      this.loadData();
    },
    handleCurrentChange(val) {
      this.pagination.currentPage = val;
      this.loadData();
    },
    handleAdd() {
      const action_type = "add";
      const class_id = (this.row as any).class_id;
      const parent_id = "#";
      (this.$refs["KTDetail"] as any).initData(
        action_type,
        class_id,
        parent_id
      );
    },
    handleReply(item) {
      const action_type = "add";
      const class_id = (this.row as any).class_id;
      const parent_id = item.ask.ask_id;
      (this.$refs["KTDetail"] as any).initData(
        action_type,
        class_id,
        parent_id
      );
    },
    submitDelete(id) {
      ApiService.post("MemberAsk/AskDelete", { id } as AxiosRequestConfig)
        .then(({ data }) => {
          if (data.success == true) {
            Toast.success(data.message);
            this.loadData();
          } else {
            Toast.warning(data.message);
          }
        })
        .catch((error) => {
          Toast.error(error.message);
        });
    },
    handleDelete(id) {
      Toast.confirm("是否删除？一旦提交，无法恢复！", () => {
        this.submitDelete(id);
      });
    },
    initBus() {
      Bus.$on("loadDataAsk", () => {
        this.loadData();
      });
    }
  },
  unmounted() {
    Bus.$off("loadDataAsk");
  },
  mounted() {
    this.user = store.getters.currentUser;
  }
});
